@tailwind base;
@tailwind components;
@tailwind utilities;

/* Regular */
@font-face {
  font-family: "Gilroy";
  src: url("../public/fonts/Gilroy-Regular.ttf") format("truetype");
  font-weight: normal;
}

/* Medium */
@font-face {
  font-family: "Gilroy";
  src: url("../public/fonts/Gilroy-Medium.ttf") format("truetype");
  font-weight: 500;
}

/* SemiBold */
@font-face {
  font-family: "Gilroy";
  src: url("../public/fonts/Gilroy-SemiBold.ttf") format("truetype");
  font-weight: 600;
}

/* Bold */
@font-face {
  font-family: "Gilroy";
  src: url("../public/fonts/Gilroy-Bold.ttf") format("truetype");
  font-weight: bold;
}

/* ExtraBold */
@font-face {
  font-family: "Gilroy";
  src: url("../public/fonts/Gilroy-ExtraBold.ttf") format("truetype");
  font-weight: 800;
}

/* Light */
@font-face {
  font-family: "Gilroy";
  src: url("../public/fonts/Gilroy-Light.ttf") format("truetype");
  font-weight: 300;
}

/* Thin */
@font-face {
  font-family: "Gilroy";
  src: url("../public/fonts/Gilroy-Thin.ttf") format("truetype");
  font-weight: 100;
}

/* UltraLight */
@font-face {
  font-family: "Gilroy";
  src: url("../public/fonts/Gilroy-UltraLight.ttf") format("truetype");
  font-weight: 200;
}


.p-datatable-tbody tr {
  /* background: gray ; */
}

.ribbon {
  position: absolute;
  right: -5px;
  top: -5px;
  z-index: 1;
  overflow: hidden;
  width: 75px;
  height: 75px;
  text-align: right;
}

.ribbon span {
  font-size: 9px;
  font-weight: bold;
  color: #fff;
  text-transform: uppercase;
  text-align: center;
  line-height: 20px;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  width: 100px;
  display: block;
  background: #79a70a;
  background: linear-gradient(#f70505 0%, #8f0808 100%);
  box-shadow: 0 3px 10px -5px rgba(0, 0, 0, 1);
  position: absolute;
  top: 19px;
  right: -21px;
}

.ribbon span::before {
  content: "";
  position: absolute;
  left: 0px;
  top: 100%;
  z-index: -1;
  border-left: 3px solid #8f0808;
  border-right: 3px solid transparent;
  border-bottom: 3px solid transparent;
  border-top: 3px solid #8f0808;
}

.ribbon span::after {
  content: "";
  position: absolute;
  right: 0px;
  top: 100%;
  z-index: -1;
  border-left: 3px solid transparent;
  border-right: 3px solid #8f0808;
  border-bottom: 3px solid transparent;
  border-top: 3px solid #8f0808;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

.input-placeholder-color::placeholder {
  color: #ffffff88;
}

.input-placeholder-color:-ms-input-placeholder {
  color: #ffffff88;
}

.input-placeholder-color::-ms-input-placeholder {
  color: #ffffff88;
}


#imageSwiperSlider .swiper-wrapper {
  position: relative;
  width: 100%;
  z-index: 1;
  display: flex;
  transition-property: transform;
  box-sizing: content-box;
}

#imageSwiperSlider .swiper-wrapper {
  transform-style: preserve-3d;
}

#imageSwiperSlider .imagesliderSwiper {
  width: 100%;
  height: 100%;
}

#imageSwiperSlider .swiper-slide {
  display: flex;
  justify-content: center;
  align-items: center;
  background-size: cover;
  background-position: center;
  object-fit: contain;
}


#imageSwiperSlider .swiper-slide-duplicate-active,
#imageSwiperSlider .swiper-slide-duplicate-next,
#imageSwiperSlider .swiper-slide-next,
#imageSwiperSlider .swiper-slide-duplicate-prev,
#imageSwiperSlider .swiper-slide-prev,
#imageSwiperSlider .swiper-slide-duplicate img {
  opacity: 0.3;
}

#imageSwiperSlider .swiper-pagination {
  gap: 0.1em;
}

#imageSwiperSlider .swiper-pagination-bullet {
  width: 10px;
  height: 10px;
  text-align: center;
  line-height: 20px;
  font-size: 12px;
  color: #000;
  opacity: 1;
  background: rgba(0, 0, 0, 0.2);
}

#imageSwiperSlider .swiper-pagination-bullet-active {
  color: #fff;
  background: #0185FF;
}

.clip-path-invoice {
  clip-path: polygon(
    0% 22.857%,
    0% 22.857%,
    0.025% 19.15%,
    0.099% 15.633%,
    0.216% 12.353%,
    0.373% 9.358%,
    0.566% 6.695%,
    0.791% 4.41%,
    1.044% 2.551%,
    1.322% 1.165%,
    1.619% 0.299%,
    1.932% 0%,
    100% 0%,
    90.774% 94.427%,
    90.774% 94.427%,
    90.666% 95.457%,
    90.553% 96.387%,
    90.434% 97.216%,
    90.311% 97.942%,
    90.185% 98.562%,
    90.054% 99.074%,
    89.921% 99.476%,
    89.786% 99.766%,
    89.649% 99.941%,
    89.51% 100%,
    0% 100%,
    0% 22.857%
  );
}

.clip-path-otp {
  clip-path: polygon(
    1.923% 89.744%,
    1.923% 22.749%,
    1.923% 22.749%,
    1.949% 22.089%,
    2.027% 21.437%,
    2.155% 20.797%,
    2.332% 20.17%,
    2.557% 19.56%,
    2.83% 18.969%,
    3.147% 18.4%,
    3.51% 17.857%,
    3.916% 17.341%,
    4.364% 16.856%,
    16.856% 4.364%,
    16.856% 4.364%,
    17.341% 3.916%,
    17.857% 3.51%,
    18.4% 3.147%,
    18.969% 2.83%,
    19.56% 2.557%,
    20.17% 2.332%,
    20.797% 2.155%,
    21.437% 2.027%,
    22.089% 1.949%,
    22.749% 1.923%,
    89.744% 1.923%,
    89.744% 1.923%,
    91.095% 2.032%,
    92.378% 2.348%,
    93.573% 2.853%,
    94.665% 3.531%,
    95.636% 4.364%,
    96.469% 5.335%,
    97.147% 6.427%,
    97.652% 7.622%,
    97.968% 8.905%,
    98.077% 10.256%,
    98.077% 76.177%,
    98.077% 76.177%,
    98.051% 76.837%,
    97.973% 77.489%,
    97.845% 78.129%,
    97.668% 78.756%,
    97.443% 79.366%,
    97.17% 79.957%,
    96.853% 80.525%,
    96.49% 81.069%,
    96.084% 81.585%,
    95.636% 82.07%,
    82.07% 95.636%,
    82.07% 95.636%,
    81.585% 96.084%,
    81.069% 96.49%,
    80.525% 96.853%,
    79.957% 97.17%,
    79.366% 97.443%,
    78.756% 97.668%,
    78.129% 97.845%,
    77.489% 97.973%,
    76.837% 98.051%,
    76.177% 98.077%,
    10.256% 98.077%,
    10.256% 98.077%,
    8.905% 97.968%,
    7.622% 97.652%,
    6.427% 97.147%,
    5.335% 96.469%,
    4.364% 95.636%,
    3.531% 94.665%,
    2.853% 93.573%,
    2.348% 92.378%,
    2.032% 91.095%,
    1.923% 89.744%
  );
}

.clip-path-item {
  clip-path: polygon(
    0.242% 93.701%,
    0.242% 17.144%,
    0.242% 17.144%,
    0.265% 16.66%,
    0.335% 16.183%,
    0.45% 15.714%,
    0.609% 15.255%,
    0.812% 14.808%,
    1.056% 14.376%,
    1.342% 13.959%,
    1.667% 13.561%,
    2.032% 13.184%,
    2.435% 12.829%,
    15.741% 1.984%,
    15.741% 1.984%,
    16.177% 1.656%,
    16.641% 1.359%,
    17.129% 1.093%,
    17.64% 0.861%,
    18.171% 0.661%,
    18.719% 0.496%,
    19.282% 0.367%,
    19.858% 0.273%,
    20.443% 0.216%,
    21.036% 0.197%,
    92.271% 0.197%,
    92.271% 0.197%,
    93.485% 0.277%,
    94.637% 0.508%,
    95.712% 0.878%,
    96.693% 1.374%,
    97.565% 1.984%,
    98.314% 2.695%,
    98.923% 3.495%,
    99.377% 4.37%,
    99.66% 5.309%,
    99.758% 6.299%,
    99.758% 81.596%,
    99.758% 81.596%,
    99.735% 82.079%,
    99.665% 82.556%,
    99.55% 83.025%,
    99.391% 83.484%,
    99.188% 83.931%,
    98.944% 84.364%,
    98.658% 84.78%,
    98.333% 85.178%,
    97.968% 85.556%,
    97.565% 85.911%,
    82.712% 98.016%,
    82.712% 98.016%,
    82.276% 98.344%,
    81.813% 98.641%,
    81.324% 98.907%,
    80.813% 99.139%,
    80.283% 99.339%,
    79.734% 99.504%,
    79.171% 99.633%,
    78.595% 99.727%,
    78.01% 99.784%,
    77.417% 99.803%,
    7.729% 99.803%,
    7.729% 99.803%,
    6.515% 99.723%,
    5.363% 99.492%,
    4.288% 99.122%,
    3.307% 98.626%,
    2.435% 98.016%,
    1.686% 97.305%,
    1.077% 96.505%,
    0.623% 95.63%,
    0.34% 94.691%,
    0.242% 93.701%
  );
}

.clip-path-number {
  clip-path: polygon(
    0% 11.94%,
    0% 11.94%,
    0.105% 10.004%,
    0.408% 8.166%,
    0.893% 6.453%,
    1.544% 4.889%,
    2.343% 3.497%,
    3.275% 2.304%,
    4.324% 1.333%,
    5.471% 0.609%,
    6.702% 0.156%,
    8% 0%,
    91.233% 0%,
    91.233% 0%,
    92.869% 0.246%,
    94.374% 0.946%,
    95.722% 2.047%,
    96.889% 3.492%,
    97.849% 5.227%,
    98.577% 7.198%,
    99.048% 9.348%,
    99.238% 11.624%,
    99.121% 13.97%,
    98.672% 16.332%,
    78.5% 92.451%,
    78.5% 92.451%,
    78.087% 93.77%,
    77.578% 94.986%,
    76.982% 96.091%,
    76.306% 97.077%,
    75.559% 97.934%,
    74.75% 98.655%,
    73.887% 99.231%,
    72.979% 99.652%,
    72.035% 99.912%,
    71.061% 100%,
    8% 100%,
    8% 100%,
    6.702% 99.844%,
    5.471% 99.391%,
    4.324% 98.667%,
    3.275% 97.696%,
    2.343% 96.503%,
    1.544% 95.112%,
    0.893% 93.547%,
    0.408% 91.834%,
    0.105% 89.996%,
    0% 88.06%,
    0% 11.94%
  );
}

.images-slider.swiper-slide {
  opacity: 0.25;
}

.images-slider.swiper-slide-visible {
  opacity: 1;
}

@layer utilities {
  @variants responsive {
    /* Hide scrollbar for Chrome, Safari and Opera */
    .no-scrollbar::-webkit-scrollbar {
      display: none;
    }

    /* Hide scrollbar for IE, Edge and Firefox */
    .no-scrollbar {
      -ms-overflow-style: none;
      /* IE and Edge */
      scrollbar-width: none;
      /* Firefox */
    }
  }

  .white-filter {
    @apply brightness-0 invert;
  }
}
